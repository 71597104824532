angular.module('app', [
    'ngAnimate',
    'angularMoment',
    'ui.bootstrap',
    'ui.router',
    'ui.mask',
    'credit-cards',
    'ngMessages',
    'checklist-model',
    'ui-notification',
    'google.places',
    'LocalStorageModule',
    'uiGmapgoogle-maps',
    'credit-cards',
    'infinite-scroll',
    'angularFileUpload',
    'ngDragDrop'
])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/departments');
        $stateProvider
            .state('signin', {
                url: '/signin',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/signin.html',
                        controller: 'signinController as ctrl'
                    }
                }
            })
            .state('forgot-password', {
                url: '/forgot-password',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/forgot-password.html',
                        controller: 'forgotPasswordController as ctrl'
                    }
                }
            })
            .state('reset-password', {
                url: '/reset-password/:activation',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/reset-password.html',
                        controller: 'resetPasswordController as ctrl'
                    }
                }
            })
            .state('maintenance', {
                url: '/maintenance',
                params: {
                    message: undefined
                },
                views: {
                    'main@': {
                        templateUrl: '/static/templates/maintenance.html',
                        controller: 'maintenanceController as ctrl'
                    }
                }
            })
            // .state('accounts', {
            //     url: '/accounts',
            //     views: {
            //         'main@': {
            //             template: '<organisation-accounts></organisation-accounts>'
            //         }
            //     }
            // })
            // .state('accounts.settlement', {
            //     url: '/settlement/:accountingOwnerReference/:accountReference',
            //     template: '<settlements></settlements>'
            // })
            // .state('accounts.statement', {
            //     url: '/statement/:accountingOwnerReference/:accountReference',
            //     template: '<statements></statements>'
            // })
            .state('transactions', {
                url: '/transactions/:organisationReference/:paymentMethodReference/:paymentMethodName',
                views: {
                    'main@': {
                        template: '<transactions></transactions>'
                    }
                }
            })
            .state('departments', {
                url: '/departments',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/departments.html',
                        controller: 'departmentController as ctrl'
                    }
                }
            })
            .state('departments.add', {
                url: '/add',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/department-add.html',
                        controller: 'departmentEditController as ctrl'
                    }
                }
            })
            .state('departments.edit', {
                url: '/edit/:reference',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/department-add.html',
                        controller: 'departmentEditController as ctrl'
                    }
                }
            })
            .state('payment-methods', {
                url: '/payment-methods',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/payment-methods.html',
                        controller: 'paymentMethodController as ctrl'
                    }
                }
            })
            .state('payment-methods.add', {
                url: '/:organisationReference/add',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/payment-method-add.html',
                        controller: 'cardAddController as ctrl'
                    }
                }
            })
            .state('payment-methods.edit-card', {
                url: '/:organisationReference/card/:paymentMethodReference/edit',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/payment-method-card-edit.html',
                        controller: 'paymentMethodCardEditController as ctrl'
                    }
                }
            })
            .state('payment-methods.edit-prepaid', {
                url: '/:organisationReference/prepaid/:paymentMethodReference/edit',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/payment-method-prepaid-edit.html',
                        controller: 'paymentMethodPrepaidEditController as ctrl'
                    }
                }
            })
            .state('trips.payment-methods', {
                url: '/:organisationReference/payment-methods/:paymentMethodReference',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/trips.html',
                        controller: 'tripsController as ctrl'
                    }
                }
            })
            .state('staff', {
                url: '/staff',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/staff.html',
                        controller: 'staffController as ctrl'
                    }
                }
            })
            .state('staff.payment-methods', {
                url: '/:organisationReference/payment-methods/:paymentMethodReference',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/staff.html',
                        controller: 'staffController as ctrl'
                    }
                }
            })
            .state('staff.add', {
                url: '/:organisationReference/add',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/staff-add.html',
                        controller: 'staffAddController as ctrl'
                    }
                }
            })
            .state('staff.edit', {
                url: '/:organisationReference/edit/:staffReference',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/staff-add.html',
                        controller: 'staffAddController as ctrl'
                    }
                }
            })
            .state('trips.staff', {
                url: '/:organisationReference/staff/:staffReference',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/trips.html',
                        controller: 'tripsController as ctrl'
                    }
                }
            })
            .state('trips', {
                url: '/trips',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/trips.html',
                        controller: 'tripsController as ctrl'
                    }
                }
            })
            .state('trips.receipt', {
                url: '/:organisationReference/receipt/:bookingReference',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/trip-receipt.html',
                        controller: 'tripReceiptController as ctrl'
                    }
                }
            })
            .state('activate', {
                url: '/activate/:activationToken',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/activation.html',
                        controller: 'activationController as ctrl'
                    }
                }
            })
            .state('bookings', {
                url: '/bookings',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/bookings.html',
                        controller: 'bookingsController as ctrl'
                    }
                }
            })
            .state('bookings.create', {
                url: '/create',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/bookings-create.html',
                        controller: 'bookingsCreateController as ctrl'
                    }
                }
            })
            .state('travel-policies', {
                url: '/travel-policies',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/travel-policies.html',
                        controller: 'travelPolicyController as ctrl'
                    }
                }
            })
            .state('travel-policies.add', {
                url: '/add',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/travel-policy-add.html',
                        controller: 'travelPolicyAddController as ctrl'
                    }
                }
            })
            .state('travel-policies.view', {
                url: '/:reference/details',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/travel-policy-details.html',
                        controller: 'travelPolicyEditController as ctrl'
                    }
                }
            })
            .state('travel-policies.edit', {
                url: '/:reference/edit',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/travel-policy-edit.html',
                        controller: 'travelPolicyEditController as ctrl'
                    }
                }
            })
            .state('staff.upload', {
                url: '/upload',
                views: {
                    'main@': {
                        templateUrl: '/static/templates/staff-upload.html',
                        controller: 'staffUploadController as ctrl'
                    }
                }
            });
    }])
    .config(["uibDatepickerConfig", "uibDatepickerPopupConfig", function (uibDatepickerConfig, uibDatepickerPopupConfig) {
        uibDatepickerPopupConfig.showButtonBar = false;
        uibDatepickerConfig.showWeeks = false;
    }])
    .config(["$animateProvider", function ($animateProvider) {
        // Stop animations on hide/show of icons that spin,
        // usually we show/hide these specifically to show progress
        $animateProvider.classNameFilter(/^((?!(fa-spin)).)*$/);
    }])
    .config(['uiMask.ConfigProvider', function (uiMaskConfigProvider) {
        uiMaskConfigProvider.clearOnBlur(false);
    }])
    .config(['$uibTooltipProvider', function ($uibTooltipProvider) {
        $uibTooltipProvider.setTriggers({
            'show': 'hide' // Clicks now only open the tooltip, 'close' events close it.
        });
    }])
    .factory('loadingInterceptor',
        ['$q', '$rootScope',
            function ($q, $rootScope) {
                var pendingRequests = 0;

                return {
                    request: function (config) {

                        pendingRequests++;
                        $rootScope.$broadcast('loader_show');
                        return config;
                    },

                    response: function (response) {
                        if (--pendingRequests === 0) {
                            $rootScope.$broadcast('loader_hide');
                        }
                        return response;
                    },

                    responseError: function (response) {
                        if (--pendingRequests === 0) {
                            $rootScope.$broadcast('loader_hide');
                        }
                        return $q.reject(response);
                    }
                };
            }])
    .factory('unauthorizedInterceptor', ["$q", "$rootScope", function ($q, $rootScope) {
        return {
            responseError: function (response) {
                if (response.status == 401 || response.status == 403) {
                    $rootScope.$broadcast('unauthorized');
                }
                return $q.reject(response);
            }
        };
    }])
    .factory('serverMaintenanceInterceptor', ["$q", "$rootScope", function ($q, $rootScope) {
        return {
            responseError: function (response) {
                if (response.status === 400) {
                    var message = checkResponse(response);
                    if (message && message.lastIndexOf('ingogo is undergoing scheduled maintenance.', 0) === 0) {
                        $rootScope.$broadcast('serverMaintenance', message);
                    }
                }
                return $q.reject(response);
            }
        };
    }])
    .config(["$httpProvider", function ($httpProvider) {
        $httpProvider.interceptors.push('loadingInterceptor');
        $httpProvider.interceptors.push('unauthorizedInterceptor');
        $httpProvider.interceptors.push('serverMaintenanceInterceptor');

        //initialize get if not there
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }

        //disable IE ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';

        // extra
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get.Pragma = 'no-cache';

    }])
    .config(["NotificationProvider", function (NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 2000,
            startTop: 120,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'right',
            positionY: 'top',
            replaceMessage: true
        });
    }])
    .config(["localStorageServiceProvider", function (localStorageServiceProvider) {
        localStorageServiceProvider
            .setStorageType('sessionStorage');
    }])
    .value('authenticatedStatus', {})
    .run(["bookingService", "userService", "$rootScope", "$state", "$log", "authenticatedStatus", "$q", function (bookingService, userService, $rootScope, $state, $log, authenticatedStatus, $q) {
        authenticatedStatus.user = $q.defer();
        $rootScope.$on('$stateChangeStart', function (ev, to, toParams, from, fromParams) {

            // for page refreshes F5s
            if (to.name !== 'signin' && _.isEmpty(from.name) && !_.isEmpty(localStorage.getItem('department'))) {
                $rootScope.department = angular.fromJson(localStorage.getItem('department'));
            }

            if (_.includes(['bookings', 'signin'], to.name)) {
                localStorage.removeItem('department');
                delete $rootScope.department;
            }

            return userService.isAuthenticated().then(function (authenticatedUser) {

                var noAuthenticationRequiredStates = ["signin", "activate", "forgot-password"];
                var authenticatedBookingStates = ["bookings", "bookings.create", "maintenance"];

                if (authenticatedUser && authenticatedUser.authenticated) {
                    authenticatedStatus.user.resolve(authenticatedUser.user);
                    authenticatedStatus.directUser = authenticatedUser.user;

                    if (!_.includes(noAuthenticationRequiredStates, to.name) && !_.includes(authenticatedUser.user.roles, "BUSINESS_ADMIN") &&
                        ( _.intersection(authenticatedUser.user.roles, ["BUSINESS_BOOKING", "BUSINESS_CONCIERGE"].length > 0)) && !_.includes(authenticatedBookingStates, to.name)) {
                        $state.go('bookings');
                    }
                }
            });
        });
    }]);

// private utility method

function checkResponse(response) {
    if (_.isEmpty(response) || _.isEmpty(response.data)) return undefined;
    if (!_.isEmpty(response.data.messages)) {
        return response.data.messages[0];
    }
    return response.data;
}
